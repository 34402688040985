import React, {Component} from 'react';
import {withRouter} from "../hooks/NavigationUtils";
import CircleFollower from "../component/CursorFollower";
import MenuIcon from "../component/MenuIcon/MenuIcon";
import {CircularProgress, Tooltip} from "@mui/material";
import {isMobile} from "react-device-detect";
import {ArrowBack, ArrowForward, Download} from "@mui/icons-material";


const allProjects = require("../assets/allProjects.json").allProjects

const allCompetences = [
    {name:"React", techno:"react"},
    {name:"Expo", techno:"expo"},
    {name:"Node.js", techno:"nodejs"},
    {name:"Express.js", techno:"expressjs"},
    {name:"MongoDB", techno:"mongodb"},
    {name:"MobaXterm", techno:"mobaxterm"},
    {name:"Javascript", techno:"javascript"},
    {name:"HTML", techno:"html"},
    {name:"CSS", techno:"css"},
    {name:"Illustrator", techno:"illustrator"},
    {name:"Lunacy", techno:"lunacy"}
]


// TODO
// - Page about me (remplacer page cv)

const TechnoViewer = ({techno}) => {
    //if techno is not an object, search for the corresponding object in allCompetences
    if(typeof techno === "string"){
        techno = allCompetences.find(technoObject => technoObject.techno === techno)
    }
    return (
        <Tooltip title={techno.name} placement="bottom">
            <div className={"flexCenter technoViewer"}>
                <img style={{width:"100%", objectFit:"contain"}} src={require(`../assets/${techno.techno}.png`)} alt={techno.name}/>
            </div>
        </Tooltip>
    )
}


class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldScreen: "welcome",
            currentlyChangingScreen:false,
            selectedProjectIndex:0,
            showBrowserView:true,
            scaleBackgroundLight:1,
            rightBackgroundLight:"-20vw",
            bottomBackgroundLight:"-20vw",
            expandProject:false
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false, expandProject:true})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true, expandProject:false})
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false, expandProject:true})
        }
        if(isMobile){
            let circleElement = document.querySelector('.circle');
            circleElement.style.display = "none";
        }
        window.addEventListener('resize', this.resizePage)
        let intervalRemoveWatermark = setInterval(() => {
            if(document){
                const splineViewer = document.getElementById("spline");
                if(splineViewer && splineViewer.shadowRoot){
                    const watermark = splineViewer.shadowRoot.querySelector("#logo")
                    if (watermark) {
                        watermark.remove()
                    }else{
                        clearInterval(intervalRemoveWatermark)
                    }
                }
            }
        },5)


        let menu = document.querySelector("#menu")
        let list = document.querySelector("#list")
        menu.style.zIndex = 0
        list.style.zIndex = 0
        let welcome = document.querySelector("#welcome")
        welcome.style.zIndex = 2

        // this.SwitchScreen("list")


        document.addEventListener('wheel', (event) => {
            if (this.state.oldScreen === "list" && this.state.showBrowserView) {
                let listToMove = document.querySelector(".verticalProjectsList")
                let currentIndex = this.state.selectedProjectIndex || 0
                let indexDiff = event.deltaY > 0 ? 1 : -1
                let newIndex = currentIndex + indexDiff
                if (newIndex >= 0 && newIndex < allProjects.length) {
                    this.selectProject(allProjects[newIndex], newIndex)
                }
            }
        });

        //add listener on position of mouse to slightly move the background light
        let backgroundLight = document.querySelector(".backgroundLight")
        document.addEventListener('mousemove', (event) => {
            backgroundLight.style.marginRight = -((event.clientX - window.innerWidth / 2) / (window.innerWidth / 2)*100) + "px"
            backgroundLight.style.marginBottom = -((event.clientY - window.innerHeight / 2) / (window.innerHeight / 2)*150) + "px"
        });

    }

    SwitchScreen(target){
        if(!this.state.currentlyChangingScreen && target !== this.state.oldScreen){
            this.setState({currentlyChangingScreen: true})
            let menu = document.querySelector("#menu")
            let welcome = document.querySelector("#welcome")
            let list = document.querySelector("#list")
            // let cv = document.querySelector("#cv")
            // let contact = document.querySelector("#contact")
            let propos = document.querySelector("#propos")

            let all = [menu, welcome, list,propos]

            all.forEach((element) => {
                if(element.id === target){
                    element.className = "flexCenter animFadeIn"
                    element.style.zIndex = 2
                }else if (element.id === this.state.oldScreen){
                    element.className = "flexCenter animFadeOut"
                    element.style.zIndex = 1
                    setTimeout(() => {
                        element.style.zIndex = 0
                        this.setState({currentlyChangingScreen: false})
                    }, 600)
                }
            })
            let rightBackgroundLight
            let bottomBackgroundLight
            let scaleBackgroundLight
            if(target === "list"){
                rightBackgroundLight = "-20vw"
                bottomBackgroundLight = "60vh"
                scaleBackgroundLight = "1.5"
            }else if(target === "menu"){
                rightBackgroundLight = "90vw"
                bottomBackgroundLight = "-20vh"
                scaleBackgroundLight = "1"
            }else if(target === "cv"){
                rightBackgroundLight = "40%"
                bottomBackgroundLight = "0%"
                scaleBackgroundLight = "2.5"
            }else if(target === "welcome"){
                rightBackgroundLight = "-20vw"
                bottomBackgroundLight = "-20vh"
                scaleBackgroundLight = "1"
            }else if(target === "propos"){
                rightBackgroundLight = "90vw"
                bottomBackgroundLight = "60vh"
                scaleBackgroundLight = "1"
            }


            this.setState({oldScreen: target, rightBackgroundLight, bottomBackgroundLight, scaleBackgroundLight})
        }
    }

    selectProject(projet, index){
        if(this.state.expandProject)return
        this.setState({selectedProjectIndex:index, expandProject:!this.state.showBrowserView})
        let leftPartDiv = document.querySelector(".leftPartDiv")
        let rightPartDiv = document.querySelector(".rightPartDiv")
        // let bottomPartDiv = document.querySelector(".bottomPartDiv")
        // let topPartDiv = document.querySelector(".topPartDiv")
        let listToMove = document.querySelector(".verticalProjectsList")

        let currentIndex = this.state.selectedProjectIndex || 0

        //calculate the absolute height of the project title target vs the current one
        let projectTitleTargetHeight = document.querySelector("#projectTitle_"+index).offsetTop
        let projectTitleCurrentHeight = document.querySelector("#projectTitle_"+currentIndex).offsetTop
        let distanceToMove = projectTitleCurrentHeight - projectTitleTargetHeight
        //move the list to the new position
        listToMove.style.marginTop = (parseInt(listToMove.style.marginTop) || 0) + distanceToMove+ "px"

        //calculate the actual height of the projectTitle to select
        let actualHeight = document.querySelector("#projectTitle_"+index).offsetHeight
        //increase the height of the leftPartDiv to the actual height of the projectTitle
        leftPartDiv.style.height = actualHeight + "px"
        // //decrease the height of the bottomPartDiv
        // bottomPartDiv.style.height = "calc(100% - "+(actualHeight+8)+"px)"
        // //Inscrease the height of the topPartDiv
        // topPartDiv.style.height = actualHeight+"px)"
    }

    downloadPDF(){
        //download PDFCV from assets
        const link = document.createElement('a');
        link.href = require("../assets/PDFCV.pdf");
        link.setAttribute('download', 'CV_Lucas_Rondepierre.pdf');
        document.body.appendChild(link);
        link.click();
    }

    sendMessage(e){
        e.preventDefault()
        let email = e.target.email.value
    }

    handleChange(e){
        this.setState({[e.target.name]:e.target.value})
    }

    // onMouseMoveOnCard = (e) => {
    //     const container = e.currentTarget;
    //
    //     if (this.state.showBrowserView) {
    //         const rect = container.getBoundingClientRect();
    //         const mouseX = e.clientX - rect.left;
    //         const mouseY = e.clientY - rect.top;
    //
    //         const offsetX = (mouseX - rect.width / 2) * 20 / rect.width;
    //         const offsetY = (mouseY - rect.height / 2) * 20 / rect.height;
    //
    //         const transformStyle = `perspective(1000px) rotateY(${-offsetX}deg) rotateX(${offsetY}deg) scale3d(1, 1, 1) scale(1)`;
    //         const boxShadow = '0 8px 16px rgba(0, 0, 0, 0.3)';
    //
    //         container.style.transform = transformStyle;
    //         container.style.boxShadow = boxShadow;
    //         container.style.zIndex = 99;
    //     }
    // }
    //
    // onMouseLeaveCard = (e) => {
    //     const container = e.currentTarget;
    //     container.style.transform = 'none';
    //     container.style.zIndex = 1;
    // }

    openImage(index){
        //open image in new tab

        if(this.state.oldScreen === "list"){
            window.open(require(`../assets/${allProjects[this.state.selectedProjectIndex].name}/${index}.${(allProjects[this.state.selectedProjectIndex].gif && index ===4) ? "gif":"png"}`))
        }
    }

    downloadSource(){
        //download source code of the project
        const link = document.createElement('a');
        link.href = require(`../assets/Labyrinthe Assembleur.zip`);
        link.setAttribute('download', 'ASSEMBLEUR_LR.zip');
        document.body.appendChild(link);
        link.click();
    }

    render() {
        return (
            <div className={"background flexCenter"}>
                <CircleFollower/>
                <div style={{position: "absolute", zIndex: 1, width: "100%", height: "100%", top: 0, left: 0}}>
                    {/*<SplineViewer url="https://prod.spline.design/kFKAjTT3GTOi04a3/scene.splinecode" style={{width: "100%", height: "100%"}}/>*/}
                </div>

                <div className={"flexCenter logoHome"} style={{position:"absolute", top:10, left:20}}>
                    {/* <button style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, padding:0, border:"none", marginRight:30}} onClick={() => {this.SwitchScreen("welcome")}} className={"buttonFolio buttonLike fontLatoThin"}>
                        <HomeIcon/>
                    </button> */}
                    <button style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, padding:0, border:"none"}} onClick={() => {this.SwitchScreen("welcome")}} className={"buttonFolio buttonLike fontLatoThin"}>
                        <img className={"buttonLike"} style={{width:50, zIndex: 10, padding:0, border:"none", userSelect:"none"}} src={require("../assets/logoLR.png")} alt="logoLR"/>
                    </button>
                </div>
                <div className={"flexCenter"} style={{position:"absolute", top:10, right:20}}>
                    {/* <button style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, padding:0, border:"none", marginRight:30}} onClick={() => {this.SwitchScreen("welcome")}} className={"buttonFolio buttonLike fontLatoThin"}>
                        <HomeIcon/>
                    </button> */}
                    <button style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, padding:0, border:"none"}} onClick={() => {this.SwitchScreen("menu")}} className={"buttonFolio buttonLike fontLatoThin"}>
                        <MenuIcon/>
                    </button>
                </div>

                <div className={"flexCenter"} style={{zIndex:2, width:"100vw", height:"100vh", overflow:"hidden"}}>
                    <div className={"backgroundLight growInAnimation"} style={{transform:`scale(${this.state.scaleBackgroundLight})` ,right:this.state.rightBackgroundLight, bottom:this.state.bottomBackgroundLight}}/>
                    <div id={"welcome"} className={"flexCenter"} style={{flexDirection: "column", padding: 15, alignItems: "flex-start", width: "80%", position:"absolute", overflow:"visible"}}>
                        <p className={"fontLatoRegular welcomeSlideIn"} style={{fontSize: "clamp(35px, 5vw, 45px", marginBottom: 10, opacity:0}}>Bonjour ! Je suis <span onClick={()=>{window.open("https://www.linkedin.com/in/lucas-rondepierre-0b475a174/")}} className={"selectableText"} style={{textDecoration:"underline", textDecorationColor:"#844dcc"}}>Lucas Rondepierre</span>, un développeur web fullstack. </p>
                        <p className={"fontLatoRegular welcomeSlideInSubtitle"} style={{fontSize: "clamp(15px, 4vw, 30px", marginBottom: 10, fontWeight:100, color:"#b8b8b8", opacity:0}}>Récemment diplômé d'un Bac+5 en informatique, j’ai déjà accumulé plus de 3 ans d’expérience en entreprise et suis passionné le développement depuis toujours.</p>
                        {/*<p className={"fontLatoRegular"} style={{fontSize: "clamp(25px, 5vw, 35px", marginBottom: 10}}>Je suis développeur fullstack Node.js</p>*/}
                        <div className={"flexCenter welcomeSlideInCompetences"} style={{justifyContent:"flex-start", flexWrap:"wrap" , opacity:0}}>
                            {allCompetences.map(techno => (
                                <TechnoViewer techno={techno}/>
                            ))}
                        </div>
                        <button style={{fontSize: "clamp(25px, 3vw, 35px", zIndex: 10, marginTop:20, opacity:0}} onClick={() => {this.SwitchScreen("menu")}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular welcomeSlideInButton"}>
                            En savoir plus
                        </button>
                    </div>

                    <div id={"menu"} className={"flexCenter"} style={{flexDirection: "column", padding: 15, alignItems: "flex-end", width: "80%", position:"absolute"}}>
                        <button onClick={()=>{this.SwitchScreen("welcome")}} style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, marginBottom: 20}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>
                            Accueil
                        </button>
                        <button onClick={()=>{this.SwitchScreen("list")}} style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, marginBottom: 20}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>
                            Mes projets
                        </button>
                        {/*<button onClick={()=>{this.SwitchScreen("cv")}} style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, marginBottom: 20}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>*/}
                        {/*    Mon CV*/}
                        {/*</button>*/}
                        <button onClick={()=>{this.SwitchScreen("propos")}} style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, marginBottom: 20}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>
                            À propos
                        </button>
                    </div>

                    {/*<div id={"cv"} className={"flexCenter"} style={{flexDirection: "column", padding: 0, width: "100%", height:"100vh", position:"absolute", overflow:"auto", justifyContent:"flex-start"}}>*/}
                    {/*    <button onClick={()=>{this.downloadPDF()}} style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, marginBottom: 20, marginTop:80}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>*/}
                    {/*        Télécharger*/}
                    {/*    </button>*/}
                    {/*    <div className={"flexCenter"} style={{position:"relative", width:"100%"}}>*/}
                    {/*        <img className={"cvViewer"} src={require("../assets/PDFCV.jpg")} alt="Expérience professionnelle"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div id={"propos"} className={"flexCenter"} style={{flexDirection: "column", padding: 0, width: "100%", height:"100vh", position:"absolute", overflow:"auto", justifyContent:"flex-start"}}>
                        <div className={"flexCenter"} style={{marginTop:this.state.showBrowserView ? 200:100, flexDirection:"column", width:"70%", alignItems:"flex-start"}}>
                            <p className={"fontLatoRegular"} style={{fontSize: "clamp(35px, 5vw, 45px", marginBottom: 10}}>À propos de moi</p>
                            <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 4vw, 30px", marginBottom: 20, fontWeight:100, color:"#b8b8b8"}}>Diplômé d’un <span style={{color:"#844dcc"}}>Bac+5 en Expert en Informatique et Systèmes d'Information</span> avec une spécialisation en Intelligence Artificielle à SUPINFO, j’ai plus de trois ans d'expérience professionnelle au sein d’une startup tech. Cette expérience m’a permis de <span style={{color:"#844dcc"}}>maîtriser l'ensemble des aspects du développement logiciel</span>, du front-end au back-end, en passant par le design et la gestion de serveurs Linux.</p>
                            <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 4vw, 30px", marginBottom: 10, fontWeight:100, color:"#b8b8b8"}}>Installé dans la région de La Rochelle, je suis à la recherche d’un poste dans le développement pour participer à des <span style={{color:"#844dcc"}}>projets intéressants et innovants</span>. Curieux et motivé, je souhaite rejoindre une équipe où je pourrai apporter mes compétences tout en continuant à progresser techniquement.</p>

                            <button onClick={()=>{this.downloadPDF()}} style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10, marginBottom: 10, marginTop:10}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>
                                Télécharger mon CV
                                <Download style={{marginLeft:10, width:40, height:40, transform:"translateY(8px)", pointerEvents:"none"}}/>
                            </button>

                            <p className={"fontLatoRegular"} style={{fontSize: "clamp(35px, 5vw, 45px", marginBottom: 10, marginTop:20}}>Me contacter</p>
                            <div className={"flexCenter"} style={{width:"100%", alignItems:"flex-start", flexDirection:"column", marginBottom:30}}>
                                <div className={"iconContact"}>
                                    <img style={{width:40, marginRight:10}} src={require("../assets/discord.png")} alt="discord"/>
                                    <p className={"fontLatoRegular"} style={{fontSize: "clamp(20px, 4vw, 30px", marginBottom: 10, fontWeight:100, color:"#b8b8b8"}}>lucasrdp</p>
                                </div>
                                <div className={"iconContact"}>
                                    <img style={{width:40, marginRight:10}} src={require("../assets/mail.png")} alt="mail"/>
                                    <p className={"fontLatoRegular"} style={{fontSize: "clamp(20px, 4vw, 30px", marginBottom: 10, fontWeight:100, color:"#b8b8b8"}}>lucasrondepierre@lucasrdp.fr</p>
                                </div>
                            </div>
                        </div>
                        {/*<form className={"formContact"} style={{marginTop:200}} onSubmit={(e)=>{this.sendMessage(e)}}>*/}
                        {/*    <div className={"inputWrapper"}>*/}
                        {/*        <p className={"inputLabel fontLatoRegular"}>Email</p>*/}
                        {/*        <input onChange={(e)=>{this.handleChange(e)}} name={"email"} type="email" value={this.state.email} className={"inputPortfolio"}/>*/}
                        {/*    </div>*/}
                        {/*    <div className={"inputWrapper"}>*/}
                        {/*        <p className={"inputLabel fontLatoRegular"}>Objet</p>*/}
                        {/*        <input onChange={(e)=>{this.handleChange(e)}} name={"objet"} type="text" value={this.state.objet} className={"inputPortfolio"}/>*/}
                        {/*    </div>*/}
                        {/*    <div className={"inputWrapper"}>*/}
                        {/*        <p className={"inputLabel fontLatoRegular"}>Message</p>*/}
                        {/*        <textarea rows={6} onChange={(e)=>{this.handleChange(e)}} name={"message"} value={this.state.message} className={"inputPortfolio"}/>*/}
                        {/*    </div>*/}
                        {/*</form>*/}

                    </div>

                    <div id={"list"} className={"flexCenter"} style={{width: "100%", height:"100%", alignItems:"flex-start", justifyContent:"space-between", overflow:this.state.expandProject ? "auto":(this.state.showBrowserView ? "none":"auto")}}>
                        {this.state.showBrowserView && (
                            <div className={"flexCenter"} style={{width:this.state.expandProject ? 0:"30%", overflow:"hidden", height:"100%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column",transition: "all 0.5s cubic-bezier(.25,.8,.25,1)"}}>
                                {/*<div style={{position:"relative", width:"100%",height:0}}>*/}
                                {/*    <div className='selectionIndicator'/>*/}
                                {/*</div>*/}
                                <div className={"flexCenter verticalProjectsList"} style={{flexDirection:"column", alignItems:"flex-start", padding:"5vw 0 0 5vw", transition: "all .5s cubic-bezier(.25,.8,.25,1)", zIndex:3}}>
                                    {allProjects.map((project, index) => (
                                        <p onClick={()=>{this.state.oldScreen === "list" && this.selectProject(project, index)}} className={"fontLatoRegular selectableText"} id={"projectTitle_"+index} style={{fontSize: "clamp(35px, 5vw, 45px", marginBottom: 20, zIndex:3}}>{project.name}</p>
                                    ))}
                                </div>
                            </div>
                        )}

                        {!this.state.showBrowserView && (
                            <div className={"flexCenter"} style={{position:"absolute", flexDirection:"column", left:5, height:"100vh"}}>
                                <div className={"flexCenter"} style={{height:"30vh", justifyContent:"space-evenly", flexDirection:"column"}}>
                                    <div onClick={()=>{
                                        let currentIndex = this.state.selectedProjectIndex || 0
                                        let newIndex = currentIndex -1
                                        if (newIndex >= 0 && newIndex < allProjects.length) {
                                            this.setState({selectedProjectIndex:newIndex})
                                            let divToScroll = document.querySelector("#list")
                                            divToScroll.scrollTop = 0
                                        }
                                    }} className={"circleButton buttonLike"} style={{zIndex:8}}>
                                        <ArrowForward className={"buttonLike"} style={{transform:"rotate(-90deg)", color:"#FFF", width:35, height:35,zIndex:5, userSelect:"none", pointerEvents:"none"}}/>
                                    </div>
                                    <div onClick={()=>{
                                        let currentIndex = this.state.selectedProjectIndex || 0
                                        let newIndex = currentIndex + 1
                                        if (newIndex >= 0 && newIndex < allProjects.length) {
                                            this.setState({selectedProjectIndex:newIndex})
                                            let divToScroll = document.querySelector("#list")
                                            divToScroll.scrollTop = 0
                                        }
                                    }} className={"circleButton buttonLike"} style={{zIndex:8}}>
                                        <ArrowForward className={"buttonLike"} style={{transform:"rotate(90deg)", color:"#FFF", width:35, height:35,zIndex:5, userSelect:"none", pointerEvents:"none"}}/>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={"flexCenter"} style={{flexDirection:"column", marginLeft:!this.state.showBrowserView && "10%", width:(!this.state.showBrowserView ? "85%" : (this.state.expandProject ? "100%":"65%")), height:"calc(100% - 5vw)", position:"relative", marginTop:"5vw", justifyContent:"flex-start",transition: "all 0.5s cubic-bezier(.25,.8,.25,1)"}}>
                            <div className={"flexCenter previewProjectMovableDiv"} style={{justifyContent:"flex-start", height:(this.state.showBrowserView && !this.state.expandProject)&&"80%", flexDirection:!this.state.showBrowserView && "column", minHeight:this.state.showBrowserView && "80vh", maxHeight:(this.state.showBrowserView && !this.state.expandProject) && "80vh", width:!this.state.showBrowserView ? "95%":"90%", position:"relative"}}>
                                {/*<div className={"topPartDiv"}/>*/}
                                {/*<div className={"bottomPartDiv"}/>*/}
                                {this.state.showBrowserView && (
                                    <>
                                        <div style={{width:this.state.expandProject && 0, opacity:this.state.expandProject && 0}} className={"separatorAddonArc"}/>
                                        <div style={{width:this.state.expandProject && 0}} className={"leftPartDiv"}/>
                                    </>
                                )}

                                {(this.state.expandProject && this.state.showBrowserView) && (
                                    <div onClick={()=>{this.setState({expandProject:false})}} className={"leftPartDivAddonReturn buttonLike selectableText"}>
                                        <ArrowBack style={{width:40, height:40, color:"#FFF", pointerEvents:"none"}} />
                                    </div>
                                )}
                                <div style={{borderTopLeftRadius:(this.state.expandProject || !this.state.showBrowserView) && 10}} className={"rightPartDiv"}/>
                                <div className={"rightPartDivAddon"} style={{flexDirection:!this.state.showBrowserView && "column"}}/>

                                {this.state.selectedProjectIndex !== undefined && (
                                    <>
                                        <div className={"flexCenter"} style={{width:this.state.expandProject ? (!this.state.showBrowserView ? "95%":"45%"):"100%", height:"100%", justifyContent:"space-between", alignItems:"flex-start", flexDirection:"column", zIndex:3}}>
                                            {!this.state.showBrowserView && (
                                                <p className={"fontLatoBold"} style={{fontSize: "clamp(40px, 4.5vw, 45px)", color:"#FFF", marginBottom:10}}>{allProjects[this.state.selectedProjectIndex].name}</p>
                                            )}
                                            <div className={"flexCenter"} style={{width:"100%", height:"50%", flexDirection:"row"}}>
                                                <div className={"flexCenter"} style={{flex:3, height:"100%", flexDirection:"column", justifyContent:"space-between"}}>
                                                    <div className={"flexCenter"} style={{width:"98%", height:"49%"}}>
                                                        {!this.state[`imageLoaded_1_${allProjects[this.state.selectedProjectIndex].name}`] && (
                                                            <div style={{width:0, height:"100%", position:"relative", zIndex:999}}>
                                                                <div className={"flexCenter"} style={{position:"absolute", bottom:20, left:20}}>
                                                                    <CircularProgress style={{color:"#FFF"}}/>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <img onClick={()=>{this.openImage(1)}}
                                                             onLoad={()=>{this.setState({[`imageLoaded_1_${allProjects[this.state.selectedProjectIndex].name}`]:true})}}
                                                             className={"imgDisplayDiv buttonLike"} style={{width:"100%", height:"100%", objectFit:"cover", borderRadius:10, filter: !this.state[`imageLoaded_1_${allProjects[this.state.selectedProjectIndex].name}`] && "brightness(0)"}} src={require("../assets/"+allProjects[this.state.selectedProjectIndex].name+"/1.png")} alt="project_illustration_1"/>

                                                    </div>
                                                    <div className={"flexCenter "} style={{width:"98%", height:"49%"}}>
                                                        {!this.state[`imageLoaded_2_${allProjects[this.state.selectedProjectIndex].name}`] && (
                                                            <div style={{width:0, height:"100%", position:"relative", zIndex:999}}>
                                                                <div className={"flexCenter"} style={{position:"absolute", bottom:20, left:20}}>
                                                                    <CircularProgress style={{color:"#FFF"}}/>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <img onClick={()=>{this.openImage(2)}}
                                                             onLoad={()=>{this.setState({[`imageLoaded_2_${allProjects[this.state.selectedProjectIndex].name}`]:true})}}
                                                             className={"imgDisplayDiv buttonLike"} style={{width:"100%", height:"100%", objectFit:"cover", borderRadius:10, filter: !this.state[`imageLoaded_2_${allProjects[this.state.selectedProjectIndex].name}`] && "brightness(0)"}} src={require("../assets/"+allProjects[this.state.selectedProjectIndex].name+"/2.png")} alt="project_illustration_2"/>
                                                    </div>
                                                </div>
                                                <div className={"flexCenter"} style={{flex:2, height:"100%"}}>
                                                    {!this.state[`imageLoaded_3_${allProjects[this.state.selectedProjectIndex].name}`] && (
                                                       <div style={{width:0, height:"100%", position:"relative", zIndex:999}}>
                                                            <div className={"flexCenter"} style={{position:"absolute", bottom:20, left:20}}>
                                                                <CircularProgress style={{color:"#FFF"}}/>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <img onClick={()=>{this.openImage(3)}}
                                                         onLoad={()=>{this.setState({[`imageLoaded_3_${allProjects[this.state.selectedProjectIndex].name}`]:true})}}
                                                         className={"imgDisplayDiv buttonLike"} style={{width:"98%", height:"100%", objectFit:"cover", borderRadius:10, filter: !this.state[`imageLoaded_3_${allProjects[this.state.selectedProjectIndex].name}`] && "brightness(0)"}} src={require("../assets/"+allProjects[this.state.selectedProjectIndex].name+"/3.png")} alt="project_illustration_3"/>
                                                </div>
                                                <div className={"flexCenter"} style={{flex:4, height:"100%"}}>
                                                    {!this.state[`imageLoaded_4_${allProjects[this.state.selectedProjectIndex].name}`] && (
                                                        <div style={{width:0, height:"100%", position:"relative", zIndex:999}}>
                                                            <div className={"flexCenter"} style={{position:"absolute", bottom:20, left:20}}>
                                                                <CircularProgress style={{color:"#FFF"}}/>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <img onClick={()=>{this.openImage(4)}}
                                                         onLoad={()=>{this.setState({[`imageLoaded_4_${allProjects[this.state.selectedProjectIndex].name}`]:true})}}
                                                         className={"imgDisplayDiv buttonLike"} style={{width:"98%", height:"100%", objectFit:"cover", borderRadius:10, filter: !this.state[`imageLoaded_4_${allProjects[this.state.selectedProjectIndex].name}`] && "brightness(0)"}} src={require("../assets/"+allProjects[this.state.selectedProjectIndex].name+"/4."+(allProjects[this.state.selectedProjectIndex].gif ? "gif":"png")+"")} alt="project_illustration_4"/>
                                                </div>
                                            </div>

                                            <div className={"flexCenter categoryDiv"}>
                                                <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start"}}>
                                                    <p className={"fontLatoBold"} style={{fontSize: "clamp(17px, 3vw, 22px)", color:"#9e63ec"}}>Nature du projet</p>
                                                    <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 3vw, 20px)", color:"#ededed"}}>{allProjects[this.state.selectedProjectIndex].type}</p>
                                                </div>
                                                <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"center"}}>
                                                    <p className={"fontLatoBold"} style={{fontSize: "clamp(17px, 3vw, 22px)", color:"#9e63ec"}}>Type de projet</p>
                                                    <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 3vw, 20px)", color:"#ededed"}}>{allProjects[this.state.selectedProjectIndex].quoi}</p>
                                                </div>
                                                <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-end"}}>
                                                    <p className={"fontLatoBold"} style={{fontSize: "clamp(17px, 3vw, 22px)", color:"#9e63ec"}}>Réalisé entre</p>
                                                    <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 3vw, 20px)", color:"#ededed"}}>{allProjects[this.state.selectedProjectIndex].quand}</p>
                                                </div>
                                            </div>

                                            <div className={"flexCenter categoryDiv"}>
                                                <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start"}}>
                                                    <p className={"fontLatoBold"} style={{fontSize: "clamp(17px, 3vw, 22px)", color:"#9e63ec"}}>Description</p>
                                                    <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 3vw, 20px)", color:"#ededed"}}>{allProjects[this.state.selectedProjectIndex].desc}</p>
                                                </div>
                                            </div>

                                            <div className={"flexCenter"} style={{justifyContent:"space-between", width:"100%"}}>
                                                <div className={"flexCenter"} style={{justifyContent:"flex-start", width:this.state.expandProject ? "100%":"60%", flexWrap:"wrap"}}>
                                                    {allProjects[this.state.selectedProjectIndex].techno.map(techno => (
                                                        <TechnoViewer techno={techno}/>
                                                    ))}
                                                </div>
                                                {!this.state.expandProject && (
                                                    <button onClick={()=>{this.setState({expandProject:true})}} style={{fontSize: "clamp(20px, 3vw, 35px", zIndex: 10}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>
                                                        En savoir plus
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        {this.state.expandProject && (
                                            <div className={"flexCenter"} style={{width:!this.state.showBrowserView ? "100%":"calc(55% - 10px)", marginLeft:10, height:"100%", justifyContent:"space-between", alignItems:"flex-start", flexDirection:"column", zIndex:3, boxShadow:"inset 2px 0 0 #FFFFFF11"}}>
                                                <div className={"flexCenter categoryDiv"}>
                                                    <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start", padding:10}}>
                                                        <p className={"fontLatoBold"} style={{fontSize: "clamp(19px, 3.5vw, 25px)", color:"#9e63ec"}}>Contexte</p>
                                                        <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 3vw, 20px", color:"#ededed"}}>{allProjects[this.state.selectedProjectIndex].contexte}</p>
                                                    </div>
                                                </div>
                                                <div className={"flexCenter categoryDiv"}>
                                                    <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-end", padding:10}}>
                                                        <p className={"fontLatoBold"} style={{fontSize: "clamp(19px, 3.5vw, 25px)", color:"#9e63ec"}}>Fonctionnalités</p>
                                                        <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 3vw, 20px", color:"#ededed", textAlign:"right"}}>{allProjects[this.state.selectedProjectIndex].fonction}</p>
                                                    </div>
                                                </div>
                                                <div className={"flexCenter categoryDiv"}>
                                                    <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start", padding:10}}>
                                                        <p className={"fontLatoBold"} style={{fontSize: "clamp(19px, 3.5vw, 25px)", color:"#9e63ec"}}>Défis</p>
                                                        <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 3vw, 20px", color:"#ededed"}}>{allProjects[this.state.selectedProjectIndex].defis}</p>
                                                    </div>
                                                </div>
                                                <div className={"flexCenter categoryDiv"}>
                                                    <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-end", padding:10}}>
                                                        <p className={"fontLatoBold"} style={{fontSize: "clamp(19px, 3.5vw, 25px)", color:"#9e63ec"}}>Équipe</p>
                                                        <p className={"fontLatoRegular"} style={{fontSize: "clamp(15px, 3vw, 20px", color:"#ededed", textAlign:"right"}}>{allProjects[this.state.selectedProjectIndex].collab}</p>
                                                    </div>
                                                </div>

                                                <div className={"flexCenter"} style={{justifyContent:"flex-end", width:"100%"}}>
                                                    {allProjects[this.state.selectedProjectIndex].demo === "site" && (
                                                        <button onClick={()=>{window.open(allProjects[this.state.selectedProjectIndex].website)}} style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>
                                                            Visiter une démo
                                                        </button>
                                                    )}
                                                    {allProjects[this.state.selectedProjectIndex].demo === "download" && (
                                                        <button onClick={()=>{this.downloadSource()}} style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>
                                                            Télécharger le code source
                                                        </button>
                                                    )}
                                                    {/*{allProjects[this.state.selectedProjectIndex].demo === "none" && (*/}
                                                    {/*    <button disabled={true} style={{fontSize: "clamp(25px, 5vw, 35px", zIndex: 10}} className={"buttonWelcome buttonFolio buttonLike fontLatoRegular"}>*/}
                                                    {/*        Pas de démo*/}
                                                    {/*    </button>*/}
                                                    {/*)}*/}
                                                </div>
                                            </div>
                                        )}
                                    </>

                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Portfolio)